import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PageTitle from '../components/PageTitle'

const PageWrapper = styled.div`
  padding: 2rem 0 3rem;
`
const ProjectTitle = styled.h2`
  font-weight: 300;
  font-size: 1.3rem;
  margin: 0;
  position: relative;
`

const Project = styled.article`
  padding-left: var(--pad5);
  padding-right: var(--pad2);
  padding-top: var(--pad2);
  padding-bottom: var(--pad2);
  position: relative;
  & > p {
    max-width: 36rem;
    text-decoration: none;
    color: var(--primeGray);
    margin-top: 1em;
    display: inline-block;
    &:after {
      content: '\u21D2';
      color: var(--bgColor);
      background-color: var(--offPrimeGray);
      display: inline-block;
      padding: 2px;
      margin-left: 1rem;
      font-size: 1.3rem;
      line-height: 1;
      padding: 0 0.2em 0.3em;
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 3rem;
    top: var(--pad2);
    bottom: 0;
    background: var(--primeColor);
    width: 1px;
    display: none;
  }
`

const ProjectDate = styled.time`
  display: block;
  width: 2rem;
  padding: 0.5rem;
  padding-left: 1em;
  top: var(--pad2);
  left: 0;
  position: absolute;
  border: solid 1px transparent;
`

const Arrow = styled.span`
  color: var(--bgColor);
  background-color: var(--offPrimeGray);
  display: inline-block;
  padding: 2px;
  margin-left: 1rem;
  font-size: 1.3rem;
  line-height: 1;
  padding: 0 0.2em 0.3em;
`

const ProjectLink = styled(Link)`
  text-decoration: none;
  display: block;
  border-top: solid 1px var(--offPrimeGray);
  &:hover,
  &:focus {
    outline: solid 2px var(--primeColor);
    ${Project} {
      &:after {
      }

      ${ProjectTitle} {
        color: var(--primeColor);
      }
      p:after {
        color: var(--primeColor);
        background-color: var(--bgColor);
      }
      ${ProjectDate} {
        border-color: var(--primeColor);
        color: var(--primeColor);
      }
    }
  }
`

const ProjectMonth = styled.span`
  font-size: 1rem;
`

const ProjectDay = styled.span`
  font-size: 1.5rem;
`

class WorkIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const projects = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All projects"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <PageWrapper>
          <PageTitle padded>Work</PageTitle>
          {projects.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const dateArr = node.frontmatter.date.split(' ')
            return (
              <ProjectLink to={node.fields.postPath} key={node.fields.slug}>
                <Project>
                  <ProjectTitle>{title}</ProjectTitle>
                </Project>
              </ProjectLink>
            )
          })}
        </PageWrapper>
      </Layout>
    )
  }
}

export default WorkIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { postType: { eq: "project" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 360)
          fields {
            slug
            postPath
          }
          frontmatter {
            date(formatString: "MMM DD")
            title
          }
        }
      }
    }
  }
`
